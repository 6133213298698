import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA4fYPduGwppcyFHAu5oxedz9gxC6Ktk7k",
  authDomain: "humbility-c937e.firebaseapp.com",
  projectId: "humbility-c937e",
  storageBucket: "humbility-c937e.appspot.com",
  messagingSenderId: "753306425755",
  appId: "1:753306425755:web:a36581d965854667f7693e",
  measurementId: "G-DCGTCFQ35Q"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
