import { Line } from 'react-chartjs-2';
import getChartOptions from './chart-options';
import chartData from './chart-data';
import borderAroundFillerPlugin from './border-around-filler-plugin';
import './HumbleChart.scss';
import { useEffect, useState } from 'react';

function HumbleChart() {
  const [chartOptions, setChartOptions] = useState(getChartOptions());

  useEffect(() => {
    window.addEventListener('resize', () => {
      setChartOptions(getChartOptions())
    });
  }, [])

  return (
    <div className='humble-chart'>
      <div className='contents'>
        <div className='humble-chart__top'>
          CUMULATIVE TRADING AMOUNT, USD
        </div>
        <div className='humble-chart__bot'>
          <Line id="chart" options={chartOptions} data={chartData} plugins={[borderAroundFillerPlugin]} />
        </div>
      </div>
    </div>
  );
}

export default HumbleChart;
