import AboutTeam from 'components/AboutTeam/AboutTeam';
import Grow from 'components/Grow/Grow';
import { useLayoutEffect, useState } from 'react';
import { EffectString, Parallax, ParallaxProvider } from 'react-scroll-parallax';
import { isScreenMD, isScreenSM } from 'utils/screen.utils';
import './App.scss';
import FirstView from './components/FirstView/FirstView';
import HumbleChart from './components/HumbleChart/HumbleChart';
import world from 'assets/world.png';

function App() {
  const [humbleChartTranslateY, setHumbleChartTranslateY] = useState(getHumbleChartTranslateY());
  const [aboutTeamTranslateY, setAboutTeamTranslateY] = useState(getAboutTeamTranslateY());
  const [bgImgLoaded, setBgImgLoaded] = useState(false);

  useLayoutEffect(() => {
    // Load bg img
    const preloaderImgEl = new Image();
    preloaderImgEl.onload = () => setBgImgLoaded(true);
    preloaderImgEl.src = world;

    // Smooth scrolling to anchors
    const anchorlinks = document.querySelectorAll('a[href^="#"]');
    for (const item of Array.from(anchorlinks)) {
      item.addEventListener('click', (e) => {
        const hashval = item.getAttribute('href');
        if (hashval) {
          const target = document.querySelector(hashval);
          target?.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        }

        e.preventDefault();
      });
    }

    // Change parallax configs on resize.
    window.addEventListener('resize', () => {
      setHumbleChartTranslateY(getHumbleChartTranslateY());
      setAboutTeamTranslateY(getAboutTeamTranslateY());
    });
  }, []);

  return (
    <ParallaxProvider>
      <div className="app">
        <div className='bg-img'
          style={{
            opacity: bgImgLoaded ? 1 : 0,
            backgroundImage: bgImgLoaded ? `url(${world})` : undefined
          }}></div>
        <div className='ellipse blue'></div>
        <div className='ellipse pink'></div>
        <div className='ellipse green'></div>

        <FirstView />

        <Parallax translateY={humbleChartTranslateY}>
          <HumbleChart />
        </Parallax>

        <Parallax translateY={aboutTeamTranslateY}>
          <AboutTeam />
        </Parallax>

        <Grow />
      </div>
    </ParallaxProvider>

  );
}

export default App;

const getAboutTeamTranslateY = (): EffectString => {
  if (isScreenMD() && !isScreenSM()) {
    return ['-20%', '10%']
  } else if (isScreenSM()) {
    return ['-12%', '7%'];
  } else {
    return ['-27%', '10%'];
  }
}

const getHumbleChartTranslateY = (): EffectString => {
  if (isScreenSM()) {
    return ['10%', '-90%'];
  } else {
    return ['0%', '-90%'];
  }
}
