import './Grow.scss';

function Grow() {
  return (
    <div className='grow' id='grow'>
      <div className='contents'>
        <div className='grow__top'>
          We seek to work with the brightest talent from the region
        </div>

        <div className='grow__bot'>
          <div className='contacts'>
            <div className='contacts__top'>WRITE TO US</div>
            <a href="mailto:dev@humbility.io" rel="noreferrer" target="_blank">dev@humbility.io</a>
            <a href="https://www.linkedin.com/company/humbility-trading/about/" rel="noreferrer" target="_blank">LinkedIn</a>
          </div>
          <div className='copyright'>© {new Date().getFullYear()} "UAB Humbility" J. Balcikonio str. 9, Vilnius, Lithuania</div>
        </div>
      </div>
    </div>
  );
}

export default Grow;
