import { Chart } from "chart.js";

const borderAroundFillerPlugin = {
  getLinePosition: function (chart: Chart, pointIndex: number): [number, number] {
    const meta = chart.getDatasetMeta(0);
    const data = meta.data;
    return [data[pointIndex].x, data[pointIndex].y];
  },
  renderVerticalLine: function (chartInstance: Chart, pointIndex: number): void {
    const [lineLeftOffset, lineTopOffset] = this.getLinePosition(chartInstance, pointIndex);
    const scale = chartInstance.scales.y;
    const context = chartInstance.ctx;

    context.beginPath();
    context.strokeStyle = '#62C759';
    context.setLineDash([5, 5]);
    context.moveTo(lineLeftOffset, lineTopOffset + 6);
    context.lineTo(lineLeftOffset, scale.bottom);
    context.stroke();
    context.setLineDash([]);
  },
  renderHorizontalLine: function (chartInstance: Chart): void {
    const [lineLeftOffset0] = this.getLinePosition(chartInstance, 0);
    const [lineLeftOffset1] = this.getLinePosition(chartInstance, 7);
    const scale = chartInstance.scales.y;
    const context = chartInstance.ctx;

    context.beginPath();
    context.strokeStyle = '#62C759';
    context.setLineDash([4, 4]);
    context.moveTo(lineLeftOffset0, scale.bottom);
    context.lineTo(lineLeftOffset1, scale.bottom);
    context.stroke();
    context.setLineDash([]);
  },

  id: 'borderAroundFiller',
  afterDatasetsDraw: function (chart: Chart) {
    this.renderVerticalLine(chart, 0);
    this.renderHorizontalLine(chart);
    this.renderVerticalLine(chart, 7);
  }
};

export default borderAroundFillerPlugin;
