import { VerticalLineSVG } from 'components/svg/svg';
import './HumbleList.scss';

function HumbleList({ title, items }: { title: string; items: string[]; }) {
  return (
    <div className='humble-list'>
      <VerticalLineSVG/>
      <div className='list'>
        <div className='list-title'>{title}</div>
        {items.map((i, idx) => <div key={idx}>— {i}</div>)}
      </div>
    </div>
  );
}

export default HumbleList;
